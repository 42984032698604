<template>
  <div class="p-2 md:px-4">
    <transition :name="transitionName" mode="out-in">
      <div v-if="isUser || !/\/site/.test($route.path)" class="mb-4">
        <div class="flex flex-row items-center gap-2 my-2">
          <q-btn to="/clients" icon="arrow_back" flat no-caps>
            <div class="q-ml-sm q-pa-xs">Back to {{ isUser ? 'Client' : 'Family' }} List</div>
          </q-btn>

          <a
            v-if="appName === 'officerprivacy'"
            style="text-decoration: none; color: #000000"
            href="https://officerprivacy.com/upgrade/"
            target="_blank"
          >
            <q-btn flat dense icon="help" no-caps>
              <div class="q-ml-sm q-pa-xs">Want OfficerPrivacy to remove your information?</div>
            </q-btn>
          </a>
        </div>

        <ApolloQuery :query="clientQuery" :variables="{ clientId: $route.params.clientId }">
          <template v-slot="{ result: { error, data }, isLoading }">
            <div v-if="isLoading || data">
              <q-dialog v-if="data" v-model="editDialog">
                <q-card class="w-full" style="max-width: 380px">
                  <q-card-section>
                    <div class="flex flex-row items-center gap-4 text-h6">
                      <div>{{ isUser ? 'Client' : 'Family Member' }} Editor</div>
                      <div class="flex-grow flex flex-row-reverse">
                        <q-btn color="secondary" flat round icon="close" v-close-popup />
                      </div>
                    </div>
                  </q-card-section>

                  <q-card-section>
                    <client-editor
                      :client="data.client"
                      @mutationCompleted="editDialog = false"
                    ></client-editor>
                  </q-card-section>
                </q-card>
              </q-dialog>

              <q-card class="q-py-md">
                <div v-if="isLoading" class="preload">
                  <facebook-loader preserveAspectRatio="xMinYMid meet" height="125px" />
                </div>

                <div v-else>
                  <q-card-section>
                    <div class="text-h5 row">
                      <q-icon color="primary" name="account_circle" size="64px" class="q-mr-sm" />
                      <div class="col column justify-center">
                        <div class="flex flex-col">
                          <div>
                            {{ data.client.firstName }}
                            {{ data.client.middleName || '' }}
                            {{ data.client.lastName }}
                          </div>

                          <div
                            v-if="
                              data.client.Client_alternateFirstNames.length > 0 ||
                              data.client.Client_alternateLastNames.length > 0
                            "
                          >
                            <div
                              class="text-gray-500 text-sm"
                              v-for="(_, index) in data.client.Client_alternateFirstNames.length >
                              data.client.Client_alternateLastNames.length
                                ? data.client.Client_alternateFirstNames
                                : data.client.Client_alternateLastNames"
                              :key="index"
                            >
                              {{
                                data.client.Client_alternateFirstNames[index]?.value ||
                                data.client.firstName
                              }}
                              {{
                                data.client.Client_alternateLastNames[index]?.value ||
                                data.client.lastName
                              }}
                            </div>
                          </div>

                          <div class="text-gray-500 text-sm">
                            {{ data.client.email }}
                            <span v-if="isUser">
                              •
                              <a
                                href="javascript:;"
                                v-if="data.client.status === 'MONITORING'"
                                @click="showMonitoringDetails(data.client)"
                              >
                                {{
                                  data.client.status
                                    .toLowerCase()
                                    .replace(/^./, (t) => t.toUpperCase())
                                }}
                              </a>

                              <span v-else>
                                {{
                                  data.client.status
                                    .toLowerCase()
                                    .replace(/^./, (t) => t.toUpperCase())
                                }}
                              </span>
                              •
                                {{
                                  data.client.ClientType
                                    .toLowerCase()
                                    .replace(/^./, (t) => t.toUpperCase())
                                }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="gt-xs flex flex-row items-center text-sm">
                        <q-btn
                          v-if="isUser && appName === 'officerprivacy'"
                          @click="
                            data.client.addresses.length === 1 &&
                              quickSearchAllSites(data.client, 0)
                          "
                          class="q-mx-xs"
                          label="Quick Search All"
                          flat
                          no-caps
                        >
                          <q-menu v-if="data.client.addresses.length > 1">
                            <q-list style="min-width: 100px">
                              <q-item
                                v-for="(address, index) of data.client.addresses"
                                :key="index"
                                clickable
                                @click="quickSearchAllSites(data.client, index)"
                                v-close-popup
                              >
                                <q-item-section>
                                  <q-item-label>Address #{{ index + 1 }}</q-item-label>

                                  <q-item-label caption>
                                    {{ address.streetAddress }}, {{ address.city }},
                                    {{ address.state }}, {{ address.zipCode }}
                                  </q-item-label>
                                </q-item-section>
                              </q-item>
                            </q-list>
                          </q-menu>
                        </q-btn>

                        <q-btn @click="editDialog = true" round flat icon="edit">
                          <q-tooltip>Edit {{ isUser ? 'client' : 'family member' }}</q-tooltip>
                        </q-btn>

                        <q-btn
                          v-if="
                            currentUser &&
                            (currentUser.authLevel === 'ADMIN' || currentUser.authLevel === 'OWNER')
                          "
                          @click="deleteClient"
                          round
                          flat
                          icon="delete"
                        >
                          <q-tooltip>Delete Client</q-tooltip>
                        </q-btn>

                        <q-btn v-if="isUser" flat round icon="assignment_returned">
                          <q-tooltip>Download report</q-tooltip>
                          <q-menu>
                            <q-list style="min-width: 100px">
                              <q-item
                                v-for="reportType of [
                                  'Initial',
                                  'Intermediate',
                                  'Final',
                                  'Monitored',
                                ]"
                                :key="reportType"
                                @click="generateReport(data.client, reportType)"
                                clickable
                                v-close-popup
                              >
                                <q-item-section>{{ reportType }}</q-item-section>
                              </q-item>
                            </q-list>
                          </q-menu>
                        </q-btn>

                        <q-btn v-if="isUser" flat round icon="outgoing_mail">
                          <q-tooltip>Send report</q-tooltip>
                          <q-menu>
                            <q-list style="min-width: 200px">
                              <q-item v-for="({ value }, index) of data.client.Client_emails" :key="index" clickable>
                                <q-item-section>{{ value }}</q-item-section>
                                <q-item-section side>
                                  <q-icon name="chevron_right" />
                                </q-item-section>
                                <q-menu anchor="top end" self="top start">
                                  <q-list>
                                    <q-item v-for="reportType in ['Initial', 'Intermediate', 'Final', 'Monitored', '*Monitored']" :key="reportType" clickable @click="sendReportEmail(data.client, reportType.replace('*','_'), value)" v-close-popup>
                                      <q-item-section>{{ reportType }}</q-item-section>
                                    </q-item>
                                  </q-list>
                                </q-menu>
                              </q-item>
                            </q-list>
                          </q-menu>
                        </q-btn>

                        <q-btn
                          @click="moveClientToMonitoring(data.client.id)"
                          :loading="movingClientToMonitoring"
                          v-if="data.client.status === 'REMOVAL' && isUser"
                          flat
                          round
                          icon="check"
                        >
                          <q-tooltip>Move client to monitoring</q-tooltip>
                        </q-btn>

                        <q-btn
                          @click="markClientAsScanned(data.client.id)"
                          :loading="markingClientAsScanned"
                          v-if="data.client.status === 'MONITORING' && isUser"
                          flat
                          round
                          icon="check"
                        >
                          <q-tooltip>Mark client as manually scanned</q-tooltip>
                        </q-btn>
                      </div>

                      <q-btn class="xs" flat round icon="more_vert">
                        <q-menu>
                          <q-list>
                            <q-item v-close-popup clickable @click="editDialog = true">
                              <q-item-section avatar>
                                <q-icon name="edit" />
                              </q-item-section>
                              <q-item-section>Edit</q-item-section>
                            </q-item>

                            <q-item
                              v-close-popup
                              clickable
                              @click="deleteClient"
                              v-if="
                                currentUser &&
                                (currentUser.authLevel === 'ADMIN' ||
                                  currentUser.authLevel === 'OWNER')
                              "
                            >
                              <q-item-section avatar>
                                <q-icon name="delete" />
                              </q-item-section>

                              <q-item-section>Delete</q-item-section>
                            </q-item>

                            <q-item v-if="isUser" clickable>
                              <q-item-section>Download Report</q-item-section>

                              <q-item-section side>
                                <q-icon name="keyboard_arrow_right" />
                              </q-item-section>

                              <q-menu>
                                <q-list style="min-width: 100px">
                                  <q-item
                                    v-close-popup
                                    v-for="reportType of [
                                      'Initial',
                                      'Intermediate',
                                      'Final',
                                      'Monitored',
                                    ]"
                                    :key="reportType"
                                    @click="generateReport(data.client, reportType)"
                                    clickable
                                  >
                                    <q-item-section>{{ reportType }}</q-item-section>
                                  </q-item>
                                </q-list>
                              </q-menu>
                            </q-item>

                            <q-item v-if="isUser" clickable>
                              <q-item-section>Send Report</q-item-section>

                              <q-item-section side>
                                <q-icon name="keyboard_arrow_right" />
                              </q-item-section>

                              <q-menu>
                                <q-list style="min-width: 200px">
                                  <q-item v-for="({ value }, index) of data.client.Client_emails" :key="index" clickable>
                                    <q-item-section>{{ value }}</q-item-section>
                                    <q-item-section side>
                                      <q-icon name="chevron_right" />
                                    </q-item-section>
                                    <q-menu anchor="top end" self="top start">
                                      <q-list>
                                        <q-item v-for="reportType in ['Initial', 'Intermediate', 'Final', 'Monitored', '*Monitored']" :key="reportType" clickable @click="sendReportEmail(data.client, reportType, value)" v-close-popup>
                                          <q-item-section>{{ reportType }}</q-item-section>
                                        </q-item>
                                      </q-list>
                                    </q-menu>
                                  </q-item>
                                </q-list>
                              </q-menu>
                            </q-item>

                            <q-item
                              @click="moveClientToMonitoring(data.client.id)"
                              :loading="movingClientToMonitoring"
                              v-if="data.client.status === 'REMOVAL' && isUser"
                              clickable
                              v-close-popup
                            >
                              <q-item-section avatar>
                                <q-icon name="check" />
                              </q-item-section>

                              <q-item-section>Move Client to Monitoring</q-item-section>
                            </q-item>

                            <q-item
                              @click="markClientAsScanned(data.client.id)"
                              clickable
                              v-close-popup
                              v-if="data.client.status === 'MONITORING' && isUser"
                            >
                              <q-item-section avatar>
                                <q-icon name="check" />
                              </q-item-section>

                              <q-item-section>Mark Client as Manually Scanned</q-item-section>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                    </div>
                  </q-card-section>

                  <q-card-section v-if="isUser">
                    <div class="row justify-around">
                      <div>
                        <information-label>Emails:</information-label>
                        <div v-for="({ value }, index) of data.client.Client_emails" :key="index">
                          {{ value }}
                        </div>
                      </div>

                      <div>
                        <information-label>Addresses:</information-label>
                        <div v-for="(address, index) of data.client.addresses" :key="index">
                          {{ address.streetAddress }}, {{ address.city }}, {{ address.state }},
                          {{ address.zipCode }}
                        </div>
                      </div>

                      <div>
                        <information-label>Phone Numbers:</information-label>
                        <div
                          v-for="({ value }, index) of data.client.Client_phoneNumbers"
                          :key="index"
                        >
                          {{ value }}
                        </div>
                      </div>
                    </div>
                  </q-card-section>

                  <q-card-section v-if="isUser">
                    <div class="q-pt-sm row justify-around q-gutter-lg">
                      <div v-if="data.client.dateOfBirth || data.client.age">
                        <information-label>Age:</information-label>
                        {{ getAge(data.client.dateOfBirth, data.client.age) }}
                      </div>

                      <div v-if="data.client.department">
                        <information-label>Department:</information-label>
                        {{ data.client.department }}
                      </div>

                      <div v-if="data.client.tempEmail">
                        <information-label>Temporary Email:</information-label>
                        {{ data.client.tempEmail }}
                      </div>

                      <div v-if="data.client.tempEmailPassword">
                        <information-label>Temporary Email Password:</information-label>
                        {{ data.client.tempEmailPassword }}
                      </div>
                    </div>

                    <div class="q-pt-sm row">
                      <div class="q-pa-md" v-if="data.client.otherInfo">
                        <information-label>Other Information:</information-label>
                        <div style="white-space: pre-wrap">
                          {{ data.client.otherInfo }}
                        </div>
                      </div>

                      <div class="q-pa-md q-ml-lg" v-if="data.client.Client_spouses?.length > 0">
                        <information-label>Spouse:</information-label>
                        <div v-for="({ value }, index) of data.client.Client_spouses" :key="index">
                          {{ value }}
                        </div>
                      </div>

                      <div class="q-pa-md q-ml-lg" v-if="data.client.Client_spouses?.length > 0">
                        <information-label>Spouse Email:</information-label>
                        <span v-if="data.client.spouseInfo" class="q-ml-sm">
                            <a @click="openSpousePage(data.client.spouseInfo.id)" class="cursor-pointer text-primary" target="_blank" rel="noopener noreferrer">
                              {{ data.client.spouseInfo.email }}
                            </a>
                          </span>
                      </div>

                      <div class="q-pa-md q-ml-lg">
                        <information-label>Stripe account:</information-label>
                        <div 
                          :style="{ color: getStripeStatusColor(data.client.stripeStatus?.stripeStatus ?? 'NOTFOUND'), cursor: 'pointer' }" 
                          @click="openStripeSearch(data.client.stripeAccount || data.client.email)"
                        >
                          {{ data.client.stripeAccount || data.client.email }}
                        </div>
                      </div>

                      <div class="q-pa-md q-ml-lg" v-if="data.client.sendReportsTo">
                        <information-label>Send Reports To:</information-label>
                        <div>
                          {{ data.client.sendReportsTo }}
                        </div>
                      </div>
                    </div>
                  </q-card-section>
                </div>
                <div class="row justify-end mr-4" v-if="isUser">
                    <q-btn @click="openMoreInfo" label="Pop-out Window" color="primary" />
                </div>
              </q-card>
            </div>

            <div v-else-if="error" class="q-pa-lg">
              <q-banner rounded class="bg-red-2">
                <template v-slot:avatar>
                  <q-icon name="error" color="red" />
                </template>

                An error occurred while trying to fetch client information:
                <span class="q-pl-sm">{{ error.message.replace('GraphQL error: ', '') }}</span>
              </q-banner>
            </div>
          </template>
        </ApolloQuery>

        <div v-if="isUser" class="col">
          <div class="row text-h6 justify-center mt-2">Temporary Email Sites</div>

          <div class="row justify-center">
            <q-chip
              @click="openBrowser(site.url)"
              clickable
              v-for="site in emailSites"
              :key="site.name"
              >{{ site.name }}</q-chip
            >
          </div>
        </div>
      </div>

      <div v-else></div>
    </transition>

    <router-view v-slot="{ Component }">
      <transition :name="transitionName" mode="out-in">
        <component :is="Component" @goToNextSite="goToNextSite" />
      </transition>
    </router-view>
  </div>
</template>

<script src="./Client.ts"></script>

<style lang="scss">
.preload {
  min-height: 85px;
  padding: 20px;
}
</style>
