import { sortWithDate } from '@/utils'
import { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';
import { defineComponent } from 'vue'

const QUEUE_LOG_QUERY = gql`
  query queueLog {
    queueLog {
      id
      createdAt
      event
      client {
        id
        firstName
        lastName
        email
      }
      user {
        id
        name
      }
    }
  }
`

const UNDO_QUEUE_EVENT = gql`
  mutation undoQueueEvent($key: String!) {
    undoQueueEvent(key: $key)
  }
`

export default defineComponent({
    name: 'QueueLogPage',
    setup() {
        const $q = useQuasar()
        return { $q }
    },
    data() {
        return {
            logs: [] as any[],
            queryError: null as ApolloError | null,
            table: {
                columns: [
                    { name: 'time', required: true, label: 'Time', align: 'left' as const, field: 'time', sortable: true },
                    { name: 'user', required: true, label: 'User', align: 'left' as const, field: 'user', sortable: true },
                    { name: 'client', required: true, label: 'Client', align: 'left' as const, field: 'client', sortable: true },
                    { name: 'event', required: true, label: 'Event', align: 'left' as const, field: 'event', sortable: true },
                    { name: 'undo', required: false, label: '', align: 'center' as const, field: 'undo', sortable: false },
                ],
                sortBy: 'time',
                descending: true,
                pagination: {
                    sortBy: 'time',
                    page: 1,
                    rowsPerPage: 50,
                },
            },
        }
    },

    apollo: {
        logs: {
            query: QUEUE_LOG_QUERY,
            update: (data) => {
                return data.queueLog.map((log: any) => ({
                    key: log.id,
                    time: new Date(log.createdAt),
                    user: log.user.name,
                    client: log.client.email,
                    event: log.event,
                }))
            },
            error(error) {
                this.queryError = error
            }
        }
    },

    methods: {
        sortWithDate,
        async undoEvent(id: string) {
            this.$q.dialog({
                title: 'Confirm Undo',
                message: 'Are you sure you want to undo this action?',
                cancel: true,
                persistent: true
            }).onOk(async () => {
                try {
                    await this.$apollo.mutate({
                        mutation: UNDO_QUEUE_EVENT,
                        variables: { key: id }
                    })

                    // Refresh the logs
                    await this.$apollo.queries.logs.refetch()

                    this.$q.notify({
                        message: 'Action undone successfully',
                        color: 'positive'
                    })
                } catch (error) {
                    this.$q.notify({
                        message: 'Failed to undo action',
                        color: 'negative'
                    })
                }
            })
        }
    }
})