import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "queuelog-page" }
const _hoisted_2 = { class: "bg-gray-100" }
const _hoisted_3 = {
  key: 0,
  class: "q-pa-lg error apollo"
}
const _hoisted_4 = { class: "q-pl-sm" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.queryError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_banner, {
              rounded: "",
              class: "bg-red-2"
            }, {
              avatar: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "error",
                  color: "red"
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" An error occurred while trying to fetch the queue logs: "),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.queryError.message), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_table, {
        class: "q-ma-sm",
        title: "Queue Logs",
        rows: _ctx.logs,
        "sort-method": (rows, sortBy, order) => _ctx.sortWithDate([...rows], sortBy, !order),
        columns: _ctx.table.columns,
        "row-key": "key",
        loading: _ctx.$apollo.queries.logs.loading,
        "sort-by": _ctx.table.sortBy,
        pagination: _ctx.table.pagination,
        descending: _ctx.table.descending,
        "rows-per-page-options": [50, 100, 500, 1000]
      }, {
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            class: "cursor-pointer",
            props: props
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
                return (_openBlock(), _createBlock(_component_q_td, {
                  key: col.name
                }, {
                  default: _withCtx(() => [
                    (col.name === 'time')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.row[col.field].toLocaleString()), 1))
                      : (col.name === 'undo')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            (props.row.event === 'RETURN')
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 0,
                                  icon: "undo",
                                  flat: "",
                                  round: "",
                                  dense: "",
                                  onClick: ($event: any) => (_ctx.undoEvent(props.row.key))
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.row[col.field]), 1))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "sort-method", "columns", "loading", "sort-by", "pagination", "descending"])
    ])
  ]))
}