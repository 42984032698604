<template>
  <div>
    <div v-if="queryError" class="q-pa-lg">
      <q-banner rounded class="bg-red-2">
        <template v-slot:avatar> <q-icon name="error" color="red" /> </template>An error occurred
        while trying to fetch security events:
        <span class="q-pl-sm">{{ queryError }}</span>
      </q-banner>
    </div>

    <q-table
      class="q-ma-sm"
      title="Security Events"
      :rows="securityEvents"
      :sort-method="(rows, sortBy, order) => sortWithDate(rows, sortBy, !order)"
      :columns="table.columns"
      row-key="name"
      :loading="$apollo.queries.securityEvents.loading"
      :pagination="table.pagination"
      :rows-per-page-options="[50, 100, 500, 1000]"
    >
      <template v-slot:top-right>
        <q-checkbox v-model="onlyDeleted" class="q-pr-md">Show deleted events</q-checkbox>
        <q-btn
          @click="$apollo.queries.securityEvents.refetch().catch()"
          :loading="$apollo.queries.securityEvents.loading"
          icon="refresh"
          flat
          round
        />
      </template>
      <template v-slot:body="props">
        <span style="display: contents" @click="showDetails(props.row.id)">
          <q-tr class="cursor-pointer" :props="props">
            <q-td v-for="(columnData, name) of filterColumnData(props.row)" :key="name">{{
              formatForDisplay(columnData, name)
            }}</q-td>
          </q-tr>
        </span>
      </template>
    </q-table>
  </div>
</template>

<script src="./SecurityEvents.ts"></script>
